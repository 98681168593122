import { template as template_e467ff759a2440bc93e9a3de83a7132a } from "@ember/template-compiler";
const FKLabel = template_e467ff759a2440bc93e9a3de83a7132a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
