import { template as template_102e276f837543388209f6b8367581b8 } from "@ember/template-compiler";
const WelcomeHeader = template_102e276f837543388209f6b8367581b8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
